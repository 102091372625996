import { useState, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserSegment } from './useUserState';
import utils from 'lib/utils';
import useUserState from 'lib/hooks/useUserState';
import { FF_V6_LOGIN } from 'server/lib/feature-flags';

/**
 *
 * @param {*} isHatAudienceCondition
 * @returns {{
 *   user: any;
 *   segment: UserSegment;
 *   isHatAudience: boolean;
 * }}
 */
const useMarketingHat = (
  isHatAudienceCondition = ['anonymous', 'registrant'],
) => {
  const [user, setUser] = useState(null);
  const [segment, setSegment] = useState(null);
  const userData = useUserState();

  useEffect(() => {
    if (FF_V6_LOGIN) {
      setUser(userData);
      setSegment(userData?.segment);
    } else {
      utils.getUserData((user) => {
        setUser(user);
        setSegment(user && user?.segment && utils.getUserSegment(user.segment));
      });
    }
  }, [userData]);

  return {
    user,
    segment,
    isHatAudience: isHatAudienceCondition.includes(segment),
  };
};

export default useMarketingHat;
